import React, {lazy} from "react";
import { Navigate } from "react-router-dom";


import { COMPANY_TYPES as CT } from "../globals/modules";
//Dashboard
const DashboardEcommerce = lazy(() => import("../pages/Dashboard"));

//pages
const Maintenance = lazy(() => import('../pages/Pages/Maintenance/Maintenance'));
const ComingSoon = lazy(() => import('../pages/Pages/ComingSoon/ComingSoon'));

//login
const Login = lazy(() => import("../pages/Authentication/Login"));
const ForgetPasswordPage = lazy(() => import("../pages/Authentication/ForgetPassword"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Register = lazy(() => import("../pages/Authentication/Register"));
const ResetPassword = lazy(() => import("../pages/Authentication/ResetPassword"));

const AccountSettings = lazy(() => import("../pages/Account/Settings"));

const Companies = lazy(() => import("../pages/Companies"));
const CompaniesCreate = lazy(() => import("../pages/Companies/Create"));
const CompaniesUpdate = lazy(() => import("../pages/Companies/Update"));

const AdminCompanyUsers = lazy(() => import("../pages/Companies/Users"));

const Fallback = lazy(() => import("../pages/Pages/Fallback"));
const Locations = lazy(() => import("../pages/Locations"));
const Calendar = lazy(() => import("../pages/Calendar"));
const Services = lazy(() => import("../pages/Services"));
const ServicesCreate = lazy(() => import("../pages/Services/Create.Service"));
const ServicesUpdate = lazy(() => import("../pages/Services/Update"));
const LocationsCreate = lazy(() => import("../pages/Locations/Create"));
const LocationsUpdate = lazy(() => import("../pages/Locations/Update"));

const ServiceProviders = lazy(() => import("../pages/ServiceProviders"));
const ServiceProviderCreate = lazy(() => import("../pages/ServiceProviders/Create"));
const ServiceProviderUpdate = lazy(() => import("../pages/ServiceProviders/Update"));

const CompanyUsers = lazy(() => import("../pages/ServiceProviders"));
const CompanyUserCreate = lazy(() => import("../pages/ServiceProviders/Create"));
const CompanyUserUpdate = lazy(() => import("../pages/ServiceProviders/Update"));

const Clients = lazy(() => import("../pages/Clients"));
const ClientsCreate = lazy(() => import("../pages/Clients/Create"));
const ClientsUpdate = lazy(() => import("../pages/Clients/Update"));
const ClientView = lazy(() => import("../pages/Clients/View"));
const NewClientView = lazy(() => import("../pages/Clients/NewView"));
const ClientGroupView = lazy(() => import("../pages/Clients/GroupView"));
const Transactions = lazy(() => import("../pages/Transactions"));
const TransactionsCreate = lazy(() => import("../pages/Transactions/Create"));

const Costs = lazy(() => import("../pages/Costs"));
const CostsCreate = lazy(() => import("../pages/Costs/Create"));
const CostsUpdate = lazy(() => import("../pages/Costs/Update"));

const Classes = lazy(() => import("../pages/Classes"));
const ClassesCreate = lazy(() => import("../pages/Classes/Create"));
const ClassesUpdate = lazy(() => import("../pages/Classes/Update"));

const Groups = lazy(() => import("../pages/Classes/Groups"));
const GroupsCreate = lazy(() => import("../pages/Classes/Groups/Create"));
const GroupsUpdate = lazy(() => import("../pages/Classes/Groups/Update"));

const Departments = lazy(() => import("../pages/Departments"));
const DepartmentsCreate = lazy(() => import("../pages/Departments/Create"));
const DepartmentsUpdate = lazy(() => import("../pages/Departments/Update"));

const Subscriptions = lazy(() => import("../pages/Subscriptions"));
const SubscriptionsCreate = lazy(() => import("../pages/Subscriptions/Create"));

const Registrations = lazy(() => import("../pages/Registrations"));
const RegistrationCreate = lazy(() => import("../pages/Registrations/Create"));
const RegistrationsUpdate = lazy(() => import("../pages/Registrations/Update/PayInstallmentsForm"));

const Achievements = lazy(() => import("../pages/Classes/Achievements"));
const AchievementsCreate = lazy(() => import("../pages/Classes/Achievements/Create"));
const AchievementsUpdate = lazy(() => import("../pages/Classes/Achievements/Update"));

const Purchases = lazy(() => import("../pages/Purchases"));
const PurchasesCreate = lazy(() => import("../pages/Purchases/Create"));
const PurchasesUpdate = lazy(() => import("../pages/Purchases/Update"));
const CompanySettings = lazy(() => import("../pages/Settings/Company"));
const Scheduler = lazy(() => import("../pages/Scheduler"));
const NotificationsSettings = lazy(() => import("../pages/Notifications"));
const DailyStatistics = lazy(() => import("../pages/Reports/DailySummary"));
const DailyActivityReport = lazy(() => import("../pages/Reports/DailySummary/List"));
const DailyActivityReportPDF = lazy(() => import("../pages/Reports/DailySummary/PDF"));

const Permissions = lazy(() => import("../pages/Permissions"));
const ReportsSMSUsage = lazy(() => import("../pages/Reports/Sms"));
const Payments = lazy(() => import("../pages/Payments"));
const PendingPayments = lazy(() => import("../pages/PendingPayments"));

const Onboarding = lazy(() => import("../pages/CompanyOnboarding"));

const authProtectedRoutes = (companyType, permissions) => {
	const r = [
		{ path: "/dashboard", component: <DashboardEcommerce />, role: "*" },

		// reports routes start
		{ path: "/reports/daily-summary", component: <DailyActivityReport />, role: "reports.daily-summary", companies: [CT.DENTAL] },
		{ path: "/reports/daily-statistics", component: <DailyStatistics />, role: "reports.daily-statistics", companies: [CT.DENTAL]},
		{ path: "/reports/daily-activity/pdf", component: <DailyActivityReportPDF />, role: "reports.daily-activity-pdf", companies: [CT.DENTAL]},

		{ path: "/reports/products", component: <DashboardEcommerce />, role: "reports.products", companies: [CT.DENTAL] },
		{ path: "/reports/services", component: <DashboardEcommerce />, role: "", companies: [CT.DENTAL] },
		{ path: "/reports/service-providers", component: <DashboardEcommerce />, role: "", companies: [CT.DENTAL] },

		{ path: "/reports/sms", component: <ReportsSMSUsage />, role: "reports.sms", companies: [CT.SPORTS] },

		{ path: "/index", component: <DashboardEcommerce />, role: "*" },

		{ path: "/companies", component: <Companies />, role: "company.view" },
		{ path: "/companies/create", component: <CompaniesCreate />, role: "company.create" },
		{ path: "/companies/:id", component: <CompaniesUpdate />, role: "company.update" },

		{ path: "/companies/:id/users", component: <AdminCompanyUsers />, role: "company.view" },

		{ path: "/locations", component: <Locations />, role: "location.view" },
		{ path: "/locations/create", component: <LocationsCreate />, role: "locaiton.create" },
		{ path: "/locations/:id", component: <LocationsUpdate />, role: "location.update" },

		{ path: "/services", component: <Services />, role: "services.view", companies: ["*"] },
		{ path: "/services/create", component: <ServicesCreate />, role: "services.create", companies: ["*"] },
		{ path: "/services/:id", component: <ServicesUpdate />, role: "services.update", companies: ["*"]},

		{ path: "/company-users", component: <CompanyUsers />, role: "users.view" },
		{ path: "/company-users/create", component: <CompanyUserCreate />, role: "users.create" },
		{ path: "/company-users/:id", component: <CompanyUserUpdate />, role: "users.update" },

		{ path: "/service-providers", component: <ServiceProviders />, role: "users.view" },
		{ path: "/service-providers/create", component: <ServiceProviderCreate />, role: "users.create"},
		{ path: "/service-providers/:id", component: <ServiceProviderUpdate />, role: "users.update" },

		{ path: "/clients", component: <Clients />, role: "client.view" },
		{ path: "/clients/create", component: <ClientsCreate />, role: "client.create" },
		{ path: "/clients/:id", component: <ClientView />, role: "client.view", companies: [CT.DENTAL] },
		{ path: "/clients/:id/new", component: <NewClientView />, role: "*", companies: ["*"] },
		{ path: "/clients/:id", component: <ClientGroupView />, role: "client.view", companies: [CT.SPORTS, CT.EDUCATION] },
		{ path: "/clients/:id/old", component: <ClientsUpdate />, role: "client.update" },

		{ path: "/calendar", component: <Calendar />, role: "calendar.view" },

		{ path: "/costs", component: <Costs />, role: "costs.view" },
		{ path: "/costs/create", component: <CostsCreate />, role: "costs.create" },
		{ path: "/costs/:id", component: <CostsUpdate />, role: "costs.update" },

		{ path: "/transactions", component: <Transactions />, role: "transactions.view", companies: [CT.DENTAL] },
		{ path: "/transactions/create", component: <TransactionsCreate />, role: "transactions.create", companies: [CT.DENTAL] },

		{ path: "/payments", component: <Payments />, role: "payment.view", companies: [CT.SPORTS, CT.EDUCATION] },
		{ path: "/pending-payments", component: <PendingPayments />, role: "payment.view", companies: [CT.SPORTS, CT.EDUCATION] },

		{ path: "/departments", component: <Departments />, role: "departments.view", companies: [CT.DENTAL]},
		{ path: "/departments/create", component: <DepartmentsCreate />, role: "departments.create", companies: [CT.DENTAL]},
		{ path: "/departments/:id", component: <DepartmentsUpdate />, role: "departments.update", companies: [CT.DENTAL]},

		{ path: "/subscriptions", component: <Subscriptions />, role: "subscriptions.view", companies: [CT.SPORTS]},
		{ path: "/subscriptions/create", component: <SubscriptionsCreate />, role: "subscriptions.create", companies: [CT.SPORTS]},
		{ path: "/subscriptions/:id", component: <DepartmentsUpdate />, role: "subscriptions.update", companies: [CT.SPORTS]},

		{ path: "/registrations", component: <Registrations />, role: "registrations.view", companies: [CT.EDUCATION]},
		{ path: "/registrations/create", component: <RegistrationCreate />, role: "registrations.create", companies: [CT.EDUCATION]},
		{ path: "/registrations/:id/installments", component: <RegistrationsUpdate />, role: "registrations.update", companies: [CT.EDUCATION]},

		{ path: "/courses", component: <Classes />, role: "course.view", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/create", component: <ClassesCreate />, role: "course.create", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/:id", component: <ClassesUpdate/>, role: "course.update", companies: [CT.SPORTS, CT.EDUCATION]},

		{ path: "/courses/:id/groups", component: <Groups />, role: "group.view", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/:id/groups/create", component: <GroupsCreate />, role: "group.create", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/:id/groups/:groupId/update", component: <GroupsUpdate />, role: "group.update", companies: [CT.SPORTS, CT.EDUCATION]},

		{ path: "/courses/:id/certifications", component: <Achievements />, role: "course.view", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/:id/certifications/create", component: <AchievementsCreate />, role: "course.create", companies: [CT.SPORTS, CT.EDUCATION]},
		{ path: "/courses/:id/certifications/:aId", component: <AchievementsUpdate/>, role: "course.update", companies: [CT.SPORTS, CT.EDUCATION]},

		{ path: "/permissions", component: <Permissions />, role: "roles.view", companies: ["*"]},

		{ path: "/purchases", component: <Purchases />, role: "purchases.view", companies: [CT.DENTAL]},
		{ path: "/purchases/create", component: <PurchasesCreate/>, role: "purchases.create", companies: [CT.DENTAL] },
		{ path: "/purchases/:id", component: <PurchasesUpdate/>, role: "purchases.update", companies: [CT.DENTAL] },

		{ path: "/settings/company", component: <CompanySettings />, role: "settings.company" },

		{ path: "/account/settings", component: <AccountSettings />, role: "*" },
		{ path: "/settings/notifications", component: <NotificationsSettings />, role: "settings.update" },

		{ path: "/onboarding", component: <Onboarding />, role: "*", layout: false },

		// eslint-disable-next-line react/display-name
		{
			path: "/",
			exact: true,
			component: <Navigate to={'/dashboard'} />,
			role: "*"
		},
		{ path: "*", component: <Fallback />, role: "*" },
		].filter((route) => {
			let check = true

			if(route.companies && (!route.companies.includes(companyType) && !route.companies.includes("*"))) {
				check = false
			}

			if(route.role && permissions.length) {
				if(route.role !== "*") {
					check = !!permissions[route.role]
				}
			}

			return check;
		});

	return r;
};

const publicRoutes = [
	// Authentication Page
	{ path: "/logout", component: <Logout /> },
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgetPasswordPage /> },
	{ path: "/register", component: <Register /> },

	//AuthenticationInner pages
	{ path: "/pages-maintenance", component: <Maintenance /> },
	{ path: "/pages-coming-soon", component: <ComingSoon /> },
	{ path: "/invitation/:token", component: <Register /> },
	{ path: "/reset-password/:token", component: <ResetPassword /> },
	{ path: "/schedule/:companyId", component: <Scheduler /> },

	{ path: "/", component: <Fallback /> },
];

export { authProtectedRoutes, publicRoutes };
