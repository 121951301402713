import React, {Suspense, useEffect, useState} from 'react';

import './assets/scss/themes.scss';
import Route from './Routes';

import Loading from './Layouts/Loading';
import { getCompanyByIdApi } from './helpers/api';
import { selectLoggedInCompanySuccess } from './store/actions';
import { useDispatch } from 'react-redux';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

function App() {
	const dispatch = useDispatch();
	const [authResolved, setAuthResolved] = useState(false);

	useEffect(() => {
		const getCompany = async () => {
			try {
				let user = localStorage.getItem('authUser');
				if(!user) {
					setAuthResolved(true);
					return;
				}
				user = JSON.parse(user);

				const resp = await getCompanyByIdApi(user.companyID);
				dispatch(selectLoggedInCompanySuccess(resp.company));
			} catch (e) {
				console.log(e)
			} finally {
				setAuthResolved(true);
			}
		}
		getCompany()
		}, []);

	return (
		<>
			<Suspense fallback={<Loading />}>
				{
					!authResolved ? (
						<div className="loading d-flex justify-content-center align-items-center vh-100 ">
							<div className="spinner-border text-primary " role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
						) : (
							<>
							<Route />
							</>
					)
				}
			</Suspense>
		</>
		);
}

export default App;
