import { createStore, applyMiddleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if(process.env.REACT_APP_APP_ENV === 'development') {
	middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {

	const store = createStore(
		rootReducer,
		initialState,
		composeEnhancers(
			applyMiddleware(...middlewares)
		),
	);
	sagaMiddleware.run(rootSaga);
	return store;
}
