import { APIClient } from "./api_helper"

export const registerUserApi = async (data, uuid) => {
	return await new APIClient().post(`/auth/register/${uuid}`, data);
}

export const loginUserApi = async (data) => {
	return await new APIClient().post("/auth/login", data);
}

export const checkAuthenticationApi = async () => {
	return await new APIClient().get("/auth/check-auth");
}

export const logoutUserApi = async () => {
	return await new APIClient().get("/auth/logout");
}

export const forgotPasswordRequestApi = async (email) => {
	return await new APIClient().post("/auth/forgot-password", {email})
}

export const checkResetPasswordTokenApi = async (token) => {
	return await new APIClient().post("/auth/verify-token", {token})
}

export const resetPasswordApi = async (data) => {
	return await new APIClient().post("/auth/reset-password", data)
}
