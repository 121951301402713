import { companiesMenuData } from './Companies.MenuData';
import { employeesMenuData } from './Employees.MenuData';
import { calendarMenuData } from './Calendar.MenuData';
import { clientsMenuData } from './Clients.MenuData';
import { settingsMenuData } from './Settings.MenuData';
import { reportsMenuData } from './Reports.MenuData';
import { financeMenuData } from './Finance.MenuData';

const menuData = [
  {
    id: "Dashboard",
    label: "Dashboard",
    icon: "ri-dashboard-2-line",
    link: "/dashboard",
  },
  ...calendarMenuData,
	...reportsMenuData,
	...clientsMenuData,
  ...companiesMenuData,
  ...employeesMenuData,
  ...financeMenuData,
  ...settingsMenuData,
];

export default menuData;
