export const settingsMenuData = [
	{
		id: "settings",
		label: "Settings",
		icon: "ri-building-2-line",
		link: "/settings",
		subItems: [
			{
				id: "locations-list",
				label: "Locations",
				link: "/locations",
				parentId: "settings",
			},
			{
				id: "departments-list",
				label: "Departments",
				link: "/departments",
				parentId: "settings"
			},
			{
				id: "services-list",
				label: "Services",
				link: "/services",
				parentId: "settings",
			},
			{
				id: "courses-list",
				label: "Courses",
				link: "/courses",
				parentId: "settings"
			},
			{
				id: "company-users-list",
				label: "Users",
				link: "/company-users",
				parentId: "settings",
			},
			{
				id: "permissions-settings",
				label: "Permissions",
				link: "/permissions",
				parentId: "settings",
			},
			{
				id: "company-settings",
				label: "Company",
				link: "/settings/company",
				parentId: "settings",
			},
			{
				id: "notifications-settings",
				label: "Notifications",
				link: "/settings/notifications",
				parentId: "settings",
			},
		]
	},
];
