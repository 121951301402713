export const financeMenuData = [
	{
		id: "accounting",
		label: "Accounting",
		icon: "las la-money-bill-wave",
		link: "/",
		subItems: [
			{
				id: "payments",
				label: "Payments History",
				link: "/payments",
				parentId: "clients",
			},
			{
				id: "unpaid-events",
				label: "Pending payments",
				link: "/pending-payments",
				parentId: "clients",
			},
			{
				id: "purchases-list",
				label: "Purchases",
				link: "/purchases",
				parentId: "clients",
			},
			{
				id: "transactions-list",
				label: "Transactions",
				link: "/transactions",
				parentId: "clients",
			},
			{
				id: "costs-list",
				label: "Expenses",
				link: "/costs",
				parentId: "clients",
			}
		]
	},
];
