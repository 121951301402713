export const clientsMenuData = [
	{
		id: "clients",
		label: "Clients",
		icon: "ri-calendar-event-line",
		link: "/clients",
		subItems: [
			{
				id: "clients-list",
				label: "Clients List",
				link: "/clients",
				parentId: "clients",
			},
			{
				id: "subscriptions-list",
				label: "Subscriptions",
				link: "/subscriptions",
				parentId: "settings",
			},
			{
				id: "registrations-list",
				label: "Registrations",
				link: "/registrations",
				parentId: "settings",
			},
		]
	},
];
