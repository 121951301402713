import React from 'react'

function Avatar({data, color, size = 20}) {
	return (
		<div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
			<div className="avatar-xxs">
				<div className={`avatar-title rounded-circle bg-${color} userprofile`} style={{padding: parseInt(size/2)}}>
					{data.name?.charAt(0).toUpperCase()}
				</div>
			</div>
		</div>
	)
}

export default Avatar
