import { addHours, addMinutes, differenceInDays, differenceInHours, differenceInMinutes, format, isAfter, isBefore } from "date-fns";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export function ucfirst(string) {
	if (typeof string !== 'string') return '';

	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getEarliestWorkingHour(whs) {
	const start = {
		value: 2400,
		text: "24:00",
	}
	for (const wh of whs) {
		if (wh.closed) continue;

		const sh = parseInt(wh.start.replace(':', ''));
		if (sh < start.value) {
			start.value = sh;
			start.text = wh.start;
		}
	}

	return start.text;
}

export function getLatestWorkingHour(whs) {
	const end = {
		value: 0,
		text: "00:00",
	}
	for (const wh of whs) {
		if (wh.closed) continue;

		const sh = parseInt(wh.end.replace(':', ''));

		if (sh > end.value) {
			end.value = sh;
			end.text = wh.end;
		}
	}

	return end.text;
}

export function getParamFromObj(obj, param) {
	if (!param) {
		return null;
	}

	const keys = param.split('.');
	let value = obj;

	for (let key of keys) {
		if (!value[key]) {
			return null;
		}

		value = value[key];
	}

	return value;
}

export function removeTimezone(date) {
	const year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	let hours = date.getHours();
	let minutes = date.getMinutes();
	let seconds = date.getSeconds();

	month = month + 1;
	month = month < 10 ? `0${month}` : month;
	day = day < 10 ? `0${day}` : day;
	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	seconds = seconds < 10 ? `0${seconds}` : seconds;

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function getReadableDurationBetweenDates(start, end, include = "d h m") {
	if (isBefore(end, start)) {
		const temp = start;
		start = end;
		end = temp;
	}
	const days = differenceInDays(end, start);
	const hours = differenceInHours(end, addHours(start, days * 24));
	const minutes = differenceInMinutes(end, addMinutes(addHours(start, days * 24 + hours), 0));

	let result = '';
	if (days > 0 && include.includes('d')) result += `${days}d `;
	if (hours > 0 && include.includes('h')) result += `${hours}h `;
	if (include.includes('m')) result += `${minutes}m`;

	if (result === '') {
		if (include.includes('m')) result = '0m';
		if (include.includes('h')) result = '0h';
		if (include.includes('d')) result = '0d';
	}

	return result.trim();
}

export function shiftToLocalTimeZone(datei) {
	const date = new Date(datei);
	const offset = date.getTimezoneOffset();
	return addMinutes(date, offset);
}

export function formatDate(date, fmt = "dd-MMM-yyyy") {
	if (!date) {
		return ""
	}
	return format(date, fmt)
}

export function formatDateTime(date) {
	return format(date, "dd-MMM-yyyy HH:mm")
}

export function dateComparator(param) {
	return (a, b, an, bn) => {
		const dateA = new Date(an.data[param]);
		const dateB = new Date(bn.data[param]);

		if (isBefore(dateA, dateB)) {
			return -1;
		} else if (isAfter(dateA, dateB)) {
			return 1;
		}

		return 0;
	}
}

export function numberComparator(param) {
	return (a, b, an, bn) => {
		return an.data[param] - bn.data[param];
	}
}

export function getFieldValueFromData(data, field, defaultValue = "") {
	if (!field) {
		return null;
	}
	const fields = field.split('.');
	let value = data;

	for (let f of fields) {
		if (!value[f]) {
			return defaultValue;
		}

		value = value[f];
	}

	return value;
}

export function notify(message, options = {}) {
	if (!message) {
		return;
	}

	const defOptions = {
		type: "info",
		comp: null,
		link: "",
		linkText: ""
	}

	const { type, link, linkText } = { ...defOptions, ...options };

	const LinkComp = link ? (
		<div>
			<Link to={link}>{linkText}</Link>
		</div>
	) : (<></>)

	if (type === "error") {
		toast.error((<>
			<div>
				{message}
			</div>
			{LinkComp}
		</>), {
			className: "bg-danger-subtle",
			hideProgressBar: true,
			closeButton: ({closeToast}) => (<i onClick={closeToast} className="ri ri-close-fill pointer"></i>)
		})
	}

	if (type === "success") {
		toast.success((<>
			<div>
				{message}
			</div>
			{LinkComp}
		</>), {
			className: "bg-success-subtle",
			hideProgressBar: true,
			closeButton: ({closeToast}) => (<i onClick={closeToast} className="ri ri-close-fill pointer"></i>)
		})
	}
}

