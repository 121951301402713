import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN} from "./actionTypes";
import { SELECT_LOGGED_IN_COMPANY_SUCCESS } from "../../companies/actionTypes";

import {
	apiError,
	loginSuccess,
	logoutUserSuccess,
} from "./actions";

import { getFirebaseBackend } from "../../../helpers/firebase/firebase_helper";
import {
	postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { getCompanyByIdApi, loginUserApi, logoutUserApi } from "../../../helpers/api";
import { getUserPermissionsApi } from "../../../helpers/api/permissions.api";

function* loginUser({ payload: { user, history } }) {
	try {
		const response = yield call(loginUserApi, user);
		if (response) {
			yield put(loginSuccess(response.user));

			if(response.user.company) {
				const comp = yield call(getCompanyByIdApi, response.user.company.ID);
				yield put({ type: SELECT_LOGGED_IN_COMPANY_SUCCESS, payload: comp.company });
			}

			localStorage.setItem("authUser", JSON.stringify(response.user));

			history("/");
		} else {
			yield put(apiError(response));
		}
	} catch (error) {
		yield put(apiError(error));
	}
}

function* logoutUser() {
	try {
		yield call(logoutUserApi);
	} catch (error) {
		yield put(apiError(LOGOUT_USER, error));
	} finally {
		yield put(logoutUserSuccess(LOGOUT_USER, true));
	}
}

function* socialLogin({ payload: { data, history, type } }) {
	try {
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
			const fireBaseBackend = getFirebaseBackend();
			const response = yield call(fireBaseBackend.socialLoginUser, type);
			if (response) {
				history("/dashboard");
			} else {
				history("/login");
			}
			localStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		} else {
			const response = yield call(postSocialLogin, data);
			localStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		}
		history('/dashboard');
	} catch (error) {
		yield put(apiError(error));
	}
}


function* fetchPermissions() {
	try {
		const response = yield call(getUserPermissionsApi);
		// object from array
		const permissions = {};
		response.permissions.forEach(p => {
			permissions[p] = p;
		});
		yield put({ type: "FETCH_PERMISSIONS_SUCCESS", payload: permissions });
	} catch (error) {
		yield put(apiError(error));
	}
}

function* authSaga() {
	yield takeEvery(LOGIN_USER, loginUser);
	yield takeLatest(SOCIAL_LOGIN, socialLogin);
	yield takeEvery(LOGOUT_USER, logoutUser);
	yield takeEvery("FETCH_PERMISSIONS", fetchPermissions);
}

export default authSaga;
