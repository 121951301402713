import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
//import images
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import { ucfirst } from '../../helpers/general_helper';

const ProfileDropdown = ({t}) => {

	const selectDashboardData = createSelector(
		(state) => state.Login.user,
		(user) => user
	);

	// Inside your component
	const user = useSelector(selectDashboardData);

	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};
	return (
		<React.Fragment>
			<Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
				<DropdownToggle tag="button" type="button" className="btn">
					<span className="d-flex align-items-center">
						<Avatar data={user} color={"primary"} size={30}/>
						<span className="text-start ms-xl-2">
							<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
								{user.name?.split(" ").map((n) => ucfirst(n)).join(" ")}
							</span>
						</span>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<h6 className="dropdown-header">{t("Hello ")}
						{user.name?.split(" ").map((n) => ucfirst(n)).join(" ")}!</h6>
					<DropdownItem>
						<Link to={"/account/settings"} className="text-dark">
							<i className="ri ri-user-line text-muted align-middle me-1"></i>
							{t("User settings")}
						</Link>
					</DropdownItem>
					<hr className='separator my-1'/>
					<DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
						<i className="mdi mdi-logout text-muted align-middle me-1"></i>
						<span className="align-middle" data-key="t-logout">
							{t("Logout")}
						</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
		);
};

export default withTranslation()(ProfileDropdown);
